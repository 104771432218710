/* You can add your custom css here.*/

.sd-title.sd-container-modern__title {
    justify-content: center;
}

.sd-completed-before-page h3 {
    justify-content: center;
}

/* --disabled */
[class$="--disabled"],
[class*="--disabled "] {
    /*color: black !important;*/
    opacity: .90 !important;
}
